<template>
  <div class="dashboard__grid">
    <Slider class="dashboard-slider"></Slider>
    <QuickUpload class="dashboard__quick-upload" />
    <ContentBox class="dashboard__content-box">
      <template #title>{{ $t('dashboard.content-box.title') }}</template>
      <p v-html="$t('dashboard.content-box.text')"></p>
      <Button :to="{name: 'Support'}" inverse>{{ $t('dashboard.content-box.button') }}</Button>
      <template #footer>
        <div v-html="$t('dashboard.content-box.footer')"></div>
      </template>
    </ContentBox>
    <div class="dashboard-table">
      <div class="dashboard-table__inquiries-title display-2 ta-center mb-l">{{ $t('dashboard.latest-requests.title') }}</div>
      <InquiriesPanel />
    </div>
    <QuickInquiry class="dashboard-quick-inquiry"></QuickInquiry>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ContentBox from '@/03-organisms/Content-box.vue'
import QuickInquiry from '@/03-organisms/Quick-inquiry.vue'
import Slider from '@/03-organisms/Slider.vue'
import Button from '@/01-atoms/Button.vue'
import QuickUpload from '@/03-organisms/Quick-upload.vue'
import InquiriesPanel from '@/03-organisms/Inquiries-panel.vue'

@Options({
  name: 'Dashboard',
  components: {
    InquiriesPanel,
    ContentBox,
    QuickInquiry,
    Slider,
    Button,
    QuickUpload
  }
})
export default class Dashboard extends Vue {
}
</script>
<style lang="scss" scoped>
.dashboard__grid {
  display: grid;
  grid-template-columns: 1fr 280px;
  grid-gap: $xmedium;
  align-items: flex-start;

  @include breakpoint($dashboard-breakpoint down) {
    grid-template-columns: 1fr;
  }
}

.dashboard-quick-inquiry {
  @include breakpoint($dashboard-breakpoint down) {
    display: none;
  }
}

.dashboard__quick-upload,
.dashboard-table__inquiries-title {
  display: none;
  @include breakpoint($dashboard-breakpoint down) {
    display: block;
  }
}
</style>
