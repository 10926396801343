
import { Options, Vue } from 'vue-class-component'
import ContentBox from '@/03-organisms/Content-box.vue'
import QuickInquiry from '@/03-organisms/Quick-inquiry.vue'
import Slider from '@/03-organisms/Slider.vue'
import Button from '@/01-atoms/Button.vue'
import QuickUpload from '@/03-organisms/Quick-upload.vue'
import InquiriesPanel from '@/03-organisms/Inquiries-panel.vue'

@Options({
  name: 'Dashboard',
  components: {
    InquiriesPanel,
    ContentBox,
    QuickInquiry,
    Slider,
    Button,
    QuickUpload
  }
})
export default class Dashboard extends Vue {
}
